<template>
  <ModalMedia
    :open="true"
    :aspect-ratio="image.width / image.height"
    @close="emit('close')"
  >
    <div class="relative w-full">
      <ImageWrapper :image="image" :config="{}" />
      <div
        v-if="title || description"
        class="bg-neutral-dark-2/40 text-light absolute bottom-0 w-full p-2 text-sm"
      >
        <div v-if="title" v-html="title" />
        <div
          v-if="description"
          class="break-all [&>a:hover]:no-underline [&>a]:underline"
          v-html="description"
        />
      </div>
    </div>
  </ModalMedia>
</template>

<script lang="ts" setup>
interface Props {
  image: ImageObject
  description?: string
  title?: string
}

defineProps<Props>()
const emit = defineEmits(['close'])

defineOptions({
  name: 'ImageModal',
})
</script>
