<script lang="ts" setup>
import { useActiveElement, useElementSize } from '@vueuse/core'

interface Props {
  aspectRatio: number
  open: Boolean
}

const props = defineProps<Props>()

const { t } = useI18n()
const { icons } = useDesign()
const content = ref()
const isOpen = toRef(props.open)
const { width: windowWidth, height: windowHeight } = useElementSize(content)
const height = computed(() => windowHeight.value - 80)
const width = computed(() => Math.max(375, windowWidth.value - 100))

const adjustedWidth = computed(() => {
  return Math.min(width.value, height.value * props.aspectRatio)
})

const adjustedHeight = computed(() => {
  return Math.min(height.value, width.value / props.aspectRatio)
})

async function close() {
  isOpen.value = false

  await nextTick()
  emit('close')
}

const activeElement = useActiveElement()
const emit = defineEmits(['close'])
defineExpose({ close })

defineOptions({
  name: 'ModalMedia',
})
</script>

<template>
  <Teleport to="body">
    <div
      v-if="isOpen"
      ref="content"
      data-modal-media
      class="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-[2px]"
      @click.self="close()"
    >
      <div
        class="flex w-full flex-col"
        :style="`max-width:${adjustedWidth}px;`"
      >
        <ButtonClose
          class="text-light self-end sm:translate-x-full"
          @click="close(), activeElement?.blur()"
        />

        <div
          :style="`max-width:${adjustedWidth}px; max-height: ${adjustedHeight}px;`"
        >
          <slot />
        </div>
      </div>
    </div>
  </Teleport>
</template>
