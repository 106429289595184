<script setup lang="ts">
// We need the eframeId div wrapped with a div
// because the id will be overwritten by the grapesjs id
export interface Props {
  eframeId?: string
  options: EframeOptions
}

const { eframeId = 'kinoheld-eframe', options } = defineProps<Props>()

useLegacyWidget(options)

defineOptions({
  name: 'LegacyWidget',
})
</script>

<template>
  <div data-legacy-widget>
    <div :id="eframeId"></div>
  </div>
</template>
