<script setup lang="ts">
import { TAB_VARIANT } from '@kh-ui/constants/tabs'
import type { RouteLocation, RouteLocationRaw } from 'vue-router'

interface TabItem {
  label: string
  description?: string
  icon?: string
  content?: string
  value: string
  disabled?: boolean
  slot?: string
  to?: string | RouteLocationRaw | (RouteLocation & { href: string })
}

interface Props {
  items: TabItem[]
  content?: boolean
  unmountOnHide?: boolean
  variant?: TabVariant
}

const {
  content = true,
  unmountOnHide = true,
  variant = TAB_VARIANT.NAVIGATION,
} = defineProps<Props>()

const modelValue = defineModel<string>({ default: '0' })

const tabListClasses = computed(() => ({
  'dark:border-neutral-dark-1 border-neutral-light-1 scrollbar-none overflow-x-auto border-b':
    variant === TAB_VARIANT.NAVIGATION,
  'flex gap-2 p-1 bg-neutral-light-1 rounded-full':
    variant === TAB_VARIANT.BUTTON,
}))

const tabClasses = computed(() => ({
  'flex-grow border-b-2 px-4 py-3 text-center text-base font-medium sm:min-w-[90px]':
    variant === TAB_VARIANT.NAVIGATION,
  'flex flex-1 flex-col items-center rounded-full px-6 py-2 text-center transition-colors':
    variant === TAB_VARIANT.BUTTON,
  'cursor-pointer': true,
  'opacity-50 cursor-not-allowed': false,
}))

const activeTabClasses = computed(() => ({
  'border-primary font-bold': variant === TAB_VARIANT.NAVIGATION,
  'bg-neutral-dark-1 text-white shadow-sm': variant === TAB_VARIANT.BUTTON,
}))

const inactiveTabClasses = computed(() => ({
  'border-transparent hover:border-neutral-light-2':
    variant === TAB_VARIANT.NAVIGATION,
  'hover:bg-neutral-light-2': variant === TAB_VARIANT.BUTTON,
}))

function getTabClasses(item: TabItem, index: number) {
  return [
    tabClasses.value,
    item.value === modelValue.value
      ? activeTabClasses.value
      : inactiveTabClasses.value,
    { 'opacity-50 cursor-not-allowed': item.disabled },
  ]
}

defineOptions({
  name: 'KTabs',
})
</script>

<template>
  <div class="w-full" data-k-tabs>
    <div :class="tabListClasses" role="tablist">
      <nav v-if="variant === TAB_VARIANT.NAVIGATION" class="-mb-px flex">
        <template v-for="(item, index) in items" :key="index">
          <NuxtLink
            v-if="item.to"
            :to="item.to"
            :title="item.label"
            :class="getTabClasses(item, index)"
            :aria-current="item.value === modelValue ? 'page' : undefined"
          >
            <Icon v-if="item.icon" :name="item.icon" class="size-5" />
            <span>{{ item.label }}</span>
          </NuxtLink>
          <button
            v-else
            :class="getTabClasses(item, index)"
            role="tab"
            type="button"
            :title="item.label"
            :aria-selected="item.value === modelValue"
            :aria-controls="`tab-panel-${index}`"
            :disabled="item.disabled"
            @click="modelValue = item.value"
          >
            <Icon v-if="item.icon" :name="item.icon" class="size-5" />
            <span>{{ item.label }}</span>
          </button>
        </template>
      </nav>
      <div v-else class="flex w-full">
        <template v-for="(item, index) in items" :key="index">
          <NuxtLink
            v-if="item.to"
            :to="item.to"
            :title="item.label"
            :class="getTabClasses(item, index)"
            :aria-current="item.value === modelValue ? 'page' : undefined"
          >
            <Icon v-if="item.icon" :name="item.icon" class="mb-1 size-5" />
            <span class="text-base font-medium">{{ item.label }}</span>
            <span v-if="item.description" class="mt-0.5 text-sm">{{
              item.description
            }}</span>
          </NuxtLink>
          <button
            v-else
            :class="getTabClasses(item, index)"
            role="tab"
            type="button"
            :title="item.label"
            :aria-selected="item.value === modelValue"
            :aria-controls="`tab-panel-${index}`"
            :disabled="item.disabled"
            @click="modelValue = item.value"
          >
            <Icon v-if="item.icon" :name="item.icon" class="mb-1 size-5" />
            <span class="text-base font-medium">{{ item.label }}</span>
            <span v-if="item.description" class="mt-0.5 text-sm">{{
              item.description
            }}</span>
          </button>
        </template>
      </div>
    </div>

    <div v-if="content" class="mt-4">
      <div
        v-for="(item, index) in items"
        :key="index"
        :id="`tab-panel-${index}`"
        role="tabpanel"
        :aria-labelledby="`tab-${index}`"
        :class="{ hidden: item.value !== modelValue }"
      >
        <slot
          v-if="item.value === modelValue || !unmountOnHide"
          :name="item.slot"
          :item="item"
        >
          {{ item.content }}
        </slot>
      </div>
    </div>
  </div>
</template>
