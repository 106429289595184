export default defineI18nConfig(() => {
  return {
    legacy: false,
    locale: 'de',
    defaultLocale: 'de',
    fallbackLocale: 'de',
    availableLocales: ['de', 'es', 'en'],
    numberFormats: {
      de: {
        currency: {
          style: 'currency',
          currency: 'EUR',
          notation: 'standard',
        },
      },
      es: {
        currency: {
          style: 'currency',
          currency: 'EUR',
          notation: 'standard',
        },
      },
      en: {
        currency: {
          style: 'currency',
          currency: 'EUR',
          notation: 'standard',
        },
      },
    },
    datetimeFormats: {
      de: {
        short: {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        },
        time: {
          hour: '2-digit',
          minute: '2-digit',
        },
      },
      es: {
        short: {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        },
        time: {
          hour: '2-digit',
          minute: '2-digit',
        },
      },
      en: {
        short: {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        },
        time: {
          hour: '2-digit',
          minute: '2-digit',
        },
      },
    },
  }
})
