<script setup lang="ts">
import type { CmsBlock } from '@cms/types/cms'
import { TAB_VARIANT } from '@kh-ui/constants/tabs'

interface CmsTab {
  label: string
  description?: string
  content?: string
  components?: CmsBlock[]
  value?: string
  hash?: string
  disabled?: boolean
  icon?: string
}

interface Props {
  id: string
  __typename: string
  tabs: CmsTab[]
  variant?: TabVariant
  unmountOnHide?: boolean
}

const {
  variant = TAB_VARIANT.NAVIGATION,
  unmountOnHide = true,
  tabs,
} = defineProps<Props>()

const modelValue = defineModel<string>({ default: '0' })

const items = computed(() =>
  tabs.map((tab, index) => ({
    label: tab.label,
    description: tab.description,
    value: tab.value ?? tab.hash ?? String(index),
    slot: `tab-${index}`,
    disabled: tab.disabled,
    icon: tab.icon,
    components: tab.components,
  })),
)

// Set initial tab value when items are available
watch(
  items,
  (newItems) => {
    if (newItems.length > 0 && modelValue.value === '0') {
      modelValue.value = newItems[0].value
    }
  },
  { immediate: true },
)

defineOptions({
  name: 'CmsBlockTabs',
})
</script>

<template>
  <KTabs
    v-model="modelValue"
    :items
    :variant
    :unmount-on-hide
    :data-block-id="id"
    :data-block-type="__typename"
  >
    <template v-for="(tab, index) in tabs" :key="index" #[`tab-${index}`]>
      <div v-if="tab.content" v-html="tab.content" />
      <template v-if="tab.components">
        <CmsBlockRenderer
          v-for="component in tab.components"
          :key="component.id"
          :block="component"
        />
      </template>
    </template>
  </KTabs>
</template>
