import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgAppleWalletAddDe, LazySvgAppleWalletAddEs, LazySvgIllustrationPopcorn, LazySvgLogoFilmdienst, LazySvgPaymentAmazonDark, LazySvgPaymentAmazonLight, LazySvgPaymentCreditcard, LazySvgPaymentGpayDark, LazySvgPaymentGpayLight, LazySvgPaymentKlarnaBlack, LazySvgPaymentKlarnaPink, LazySvgPaymentKlarna, LazySvgPaymentLoyalty, LazySvgPaymentPaypalDark, LazySvgPaymentPaypalLight, LazySvgPaymentUnzer, LazySvgPoweredByEventim, LazySvgPoweredByKinoheld, LazySvgSocialFacebook, LazySvgSocialInstagramOutline, LazySvgSocialInstagram, LazySvgSocialLinkedin, LazySvgSocialPinterest, LazySvgSocialTiktok, LazySvgSocialTumblr, LazySvgSocialTwitter, LazySvgSocialWhatsapp, LazySvgSocialX, LazySvgSocialYoutube, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["SvgAppleWalletAddDe", LazySvgAppleWalletAddDe],
["SvgAppleWalletAddEs", LazySvgAppleWalletAddEs],
["SvgIllustrationPopcorn", LazySvgIllustrationPopcorn],
["SvgLogoFilmdienst", LazySvgLogoFilmdienst],
["SvgPaymentAmazonDark", LazySvgPaymentAmazonDark],
["SvgPaymentAmazonLight", LazySvgPaymentAmazonLight],
["SvgPaymentCreditcard", LazySvgPaymentCreditcard],
["SvgPaymentGpayDark", LazySvgPaymentGpayDark],
["SvgPaymentGpayLight", LazySvgPaymentGpayLight],
["SvgPaymentKlarnaBlack", LazySvgPaymentKlarnaBlack],
["SvgPaymentKlarnaPink", LazySvgPaymentKlarnaPink],
["SvgPaymentKlarna", LazySvgPaymentKlarna],
["SvgPaymentLoyalty", LazySvgPaymentLoyalty],
["SvgPaymentPaypalDark", LazySvgPaymentPaypalDark],
["SvgPaymentPaypalLight", LazySvgPaymentPaypalLight],
["SvgPaymentUnzer", LazySvgPaymentUnzer],
["SvgPoweredByEventim", LazySvgPoweredByEventim],
["SvgPoweredByKinoheld", LazySvgPoweredByKinoheld],
["SvgSocialFacebook", LazySvgSocialFacebook],
["SvgSocialInstagramOutline", LazySvgSocialInstagramOutline],
["SvgSocialInstagram", LazySvgSocialInstagram],
["SvgSocialLinkedin", LazySvgSocialLinkedin],
["SvgSocialPinterest", LazySvgSocialPinterest],
["SvgSocialTiktok", LazySvgSocialTiktok],
["SvgSocialTumblr", LazySvgSocialTumblr],
["SvgSocialTwitter", LazySvgSocialTwitter],
["SvgSocialWhatsapp", LazySvgSocialWhatsapp],
["SvgSocialX", LazySvgSocialX],
["SvgSocialYoutube", LazySvgSocialYoutube],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
