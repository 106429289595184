import revive_payload_client_cMYBiF7TtB from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_64ycyfjeewgrhlsqvqihvst6te/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_B6WAJaHYFa from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_64ycyfjeewgrhlsqvqihvst6te/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_e2L7hFgZZf from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_64ycyfjeewgrhlsqvqihvst6te/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_fJnAEMEiCt from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_64ycyfjeewgrhlsqvqihvst6te/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_chNE2SicF4 from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_64ycyfjeewgrhlsqvqihvst6te/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_lBnBaIn5Us from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_64ycyfjeewgrhlsqvqihvst6te/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_cdjqijyntc from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_64ycyfjeewgrhlsqvqihvst6te/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_YCJ4H6gLx4 from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_64ycyfjeewgrhlsqvqihvst6te/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import switch_locale_path_ssr_AYSHXaDw43 from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_rollup@4_wzzchgip5t3bcppes5kopq75s4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_q6JQbKLPGM from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_rollup@4_wzzchgip5t3bcppes5kopq75s4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_NYyN9hFxRZ from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_rollup@4_wzzchgip5t3bcppes5kopq75s4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import components_plugin_KR1HBZs4kY from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/apps/site-factory/.nuxt/components.plugin.mjs";
import prefetch_client_wO6JNxYhLK from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_64ycyfjeewgrhlsqvqihvst6te/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/apps/site-factory/.nuxt/formkitPlugin.mjs";
import plugin_PEqCdyyO2J from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.2.5_@types+node@22.10.1_jiti@2.4.2_terser@5.39.0_tsx@_a2i7nocxzf3rcrhd7nn2o4k7qy/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_TWJD1V70hH from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/node_modules/.pnpm/nuxt-graphql-client@0.2.43_@parcel+watcher@2.5.1_@types+node@22.10.1_graphql-sock@1.0.1_graph_zumua3klhrxdhnxzg2ukira4vi/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import google_tag_manager_2R27v5gfAU from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/layers/base/plugins/google-tag-manager.ts";
import sanitize_html_kIz0q5HPOS from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/layers/base/plugins/sanitize-html.ts";
import sentry_client_dyxoAk6TFV from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/layers/base/plugins/sentry.client.ts";
import widget_mode_X6BjB4n2GO from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/layers/base/plugins/widget-mode.ts";
import ssg_detect_nKBNdcrgPZ from "/home/forge/deployments/sitefactory/live/2025-04-09_15-00-38_b2943b5/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_rollup@4_wzzchgip5t3bcppes5kopq75s4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_cMYBiF7TtB,
  unhead_B6WAJaHYFa,
  router_e2L7hFgZZf,
  payload_client_fJnAEMEiCt,
  navigation_repaint_client_chNE2SicF4,
  check_outdated_build_client_lBnBaIn5Us,
  view_transitions_client_cdjqijyntc,
  chunk_reload_client_YCJ4H6gLx4,
  switch_locale_path_ssr_AYSHXaDw43,
  route_locale_detect_q6JQbKLPGM,
  i18n_NYyN9hFxRZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wO6JNxYhLK,
  formkitPlugin_pZqjah0RUG,
  plugin_PEqCdyyO2J,
  plugin_TWJD1V70hH,
  google_tag_manager_2R27v5gfAU,
  sanitize_html_kIz0q5HPOS,
  sentry_client_dyxoAk6TFV,
  widget_mode_X6BjB4n2GO,
  ssg_detect_nKBNdcrgPZ
]