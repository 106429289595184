// See: https://www.lichter.io/articles/nuxt3-sentry-recipe/#conclusion

import * as Sentry from '@sentry/vue'
import pkg from '../../../package.json'
import { defineNuxtPlugin, useRuntimeConfig, type NuxtApp } from '#app'

export default defineNuxtPlugin((nuxtApp: NuxtApp) => {
  const router = useRouter()
  const runtimeConfig = useRuntimeConfig()

  if (!runtimeConfig.public.SENTRY_ENABLED) {
    return {
      provide: {
        sentrySetContext: () => {},
        sentrySetUser: () => {},
        sentrySetTag: () => {},
        sentryAddBreadcrumb: () => {},
        sentryCaptureException: () => {},
      },
    }
  }

  const integrations = []
  if (runtimeConfig.public.SENTRY_PERFORMANCE_ENABLED) {
    integrations.push(
      Sentry.browserTracingIntegration({
        router,
        enableInp: true,
        traceFetch: true,
      }),
    )
    integrations.push(
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    )
  }

  Sentry.init({
    enabled: !!runtimeConfig.public.SENTRY_ENABLED,
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    debug: runtimeConfig.public.SENTRY_DEBUG,
    dsn: runtimeConfig.public.SENTRY_DSN,
    release: pkg.version,
    environment: runtimeConfig.public.SENTRY_ENVIRONMENT,
    attachProps: true,
    logErrors: true,
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    integrations,
    tracesSampleRate: Number(runtimeConfig.public.SENTRY_TRACES_SAMPLE_RATE),
    profilesSampleRate: Number(
      runtimeConfig.public.SENTRY_PROFILES_SAMPLE_RATE,
    ),
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  }
})
