<template>
  <ModalMedia :open="true" :aspect-ratio="16 / 9" @close="emit('close')">
    <YoutubePlayer
      :remote-video-id="remoteVideoId"
      :render="renderPlayer"
      :autoplay="autoplay"
      :mute="mute"
      :controls="controls"
      class="aspect-video w-full"
    />
  </ModalMedia>
</template>

<script lang="ts" setup>
interface Props {
  remoteVideoId: string
  renderPlayer?: boolean
  autoplay?: boolean
  mute?: boolean
  controls?: boolean
}

const emit = defineEmits(['close'])

defineProps<Props>()
defineOptions({
  name: 'YoutubeModal',
})
</script>
