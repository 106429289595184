<script setup lang="ts">
const {
  userConsent,
  overrideCreateElement,
  isSettingsModalVisible,
  isInitialModalVisible,
  declineAll,
} = useConsentManager()
const { isWidgetMode } = useWidgetMode()

const route = useRoute()
const hideConsentInfo = computed(
  () => route.query['hide-consent-info'] || route.query['theme-roller'],
)

if (import.meta.client) {
  overrideCreateElement()
}

onMounted(() => {
  // force decline all if widget mode is enabled and user consent is not set
  if (isWidgetMode.value && !userConsent.value) {
    declineAll()
  }
})

defineOptions({
  name: 'ConsentManager',
})
</script>

<template>
  <ConsentManagerInitialModal
    v-if="isInitialModalVisible && !hideConsentInfo && !isWidgetMode"
  />

  <LazyConsentManagerSettingsModal
    v-if="isSettingsModalVisible && !hideConsentInfo && !isWidgetMode"
  />
</template>
